import React from 'react'
import Organizers from './Organizers';
import UUID from 'uuid'
import GeocodeJson from '../Data/Geocodes.json';
import AirtableData from '../Data/AirtableData.json';
import GroupCard from './GroupCard';

//THIS CODE IS TEMPORARY/NECESSARY BECAUSE OF OUR CURRENT AIRTABLE DATA INTEGRITY
let goodObjs = []
AirtableData.records.filter(obj => {
  if (obj.fields.Url !== 'N/A') {
      goodObjs.push(obj)
  }
  return
})


export default class OrganizersList extends React.Component {
    state = { currentPage: null, totalPages: null, allGroups: goodObjs, currentGroups: [], selectedGroup: {} }
  
  
    componentDidMount() {
      // this.setState({ allGroups: AirtableData.records })
    }
  
    onPageChanged = data => {
      const { allGroups } = this.state;
      const { currentPage, totalPages, pageLimit } = data;
      const offset = (currentPage - 1) * pageLimit;
      const currentGroups = allGroups.slice(offset, offset + pageLimit);
      this.setState({ currentPage, totalPages, currentGroups });
    }
  
    render() {
      let places = []
      GeocodeJson.locations[0].forEach((loc) => {
        places.push({ location: loc.results[0].geometry.location, name: loc.results[0].address_components[0].long_name, placeId: Math.random().toString(36).substr(2, 9) })
      }
      );
      const { currentPage, totalPages, allGroups, currentGroups } = this.state;
      const totalGroups = allGroups.length;
      const headerClass = ['text-dark py-2 pr-4 m-0', currentPage ? 'border-gray border-right' : ''].join(' ').trim();

  
  
    return (
        <div className="container mb-5">
        <div className="row d-flex flex-row py-4">

          <div className="w-100 px-4 py-5 d-flex flex-row flex-wrap align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">

              <h2 className={headerClass}>
                <strong className="test-secondary">{totalGroups}</strong> Groups
      </h2>

              {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{currentPage}</span> / <span className="font-weight-bold">{totalPages}</span>
                </span>
              )}

            </div>

            <div className="d-flex flex-row py-4 align-items-center">
              <Organizers totalRecords={totalGroups} pageLimit={9} pageNeighbours={1} onPageChanged={this.onPageChanged} />
            </div>
          </div>

          {currentGroups.map(group => <GroupCard key={group.id} group={group} />)}

        </div>
      </div>
    )
}

}