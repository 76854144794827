import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import OrganizersList from '../components/OrganizersList';
import CssBaseline from '@material-ui/core/CssBaseline';
import Map from '../components/Map';
import AirtableData from '../Data/AirtableData.json';
// import UUIDv4 from 'uuid/v4'
// import GeocodeJson from '../Data/Geocodes.json';
import Newsletter from "../containers/Newsletter/index"
import '../Css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/header'
import Footer from '../components/Footer';

class IndexPage extends Component {
  state = { currentPage: null, totalPages: null, allGroups: AirtableData.records, currentGroups: [], selectedGroup: {} }

  componentDidMount() {
    this.setState({ allGroups: AirtableData.records })
  }

  onPageChanged = data => {
    const { allGroups } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentGroups = allGroups.slice(offset, offset + pageLimit);
    this.setState({ currentPage, totalPages, currentGroups });
  }

  render() {
    // let places = []
    // GeocodeJson.locations[0].forEach((loc) => {
    //   places.push({ location: loc.results[0].geometry.location, name: loc.results[0].address_components[0].long_name, placeId: UUIDv4() })
    // }
    // );


    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          {/* <Header siteTitle={'Cassandra Network'} /> */}
          <div className="MapContainer">
            <Map center={{ lat: 0, lng: 0 }} />
          </div>
          <Container maxWidth="md" className="mobileHeader">
            <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
              The Apache® Cassandra™ Network
          </Typography>
          <div className="poweredByLink">
          <small>Powered by: <a href="https://anant.us"><small>Anant Corporation</small></a></small>
          </div>
          </Container>
          <Container className="cardGrid" maxWidth="md">
            <Grid container spacing={4}>
              <OrganizersList />
            </Grid>
          </Container>
          {/* <RegisterForm /> */}
          <Newsletter />
          <Footer />
        </main>
      </React.Fragment>
    );
  }
}
export default IndexPage;