import React from 'react';
// import Flag from 'react-flags';

const CountryCard = (props, children) => {
//   const { cca2: code2 = '', region = null, name = {}  } = props.country || {};
  return (
    <div className="col-sm-6 col-md-4 country-card" onClick={() => window.location.href = props.group.fields.Url}>
      <div className="country-card-container border-gray rounded border mx-2 my-3 d-flex flex-row align-items-center p-0 bg-light">

        {/* <div className="h-100 position-relative border-gray border-right px-2 bg-white rounded-left">

          <Flag country={'US'} format="png" pngSize={64} basePath="./img/flags" className="d-block h-100" />


        </div> */}

        <div className="px-3">

          <span className="country-name text-dark d-block font-weight-bold">{ props.group.fields["Group Name"] }</span>

          {/* <span className="country-region text-secondary text-uppercase"><a href={props.group.fields["Organizer LinkedIn"]}>Contact Organizer</a> </span> */}

          {/* <span className="country-region text-secondary text-uppercase">{ props.group.fields.City }</span> */}


        </div>

      </div>
    </div>
  )
}

export default CountryCard;
