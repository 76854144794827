import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import InfoWindowEx from "./InfoWindowEx";
import AirtableData from '../Data/AirtableData.json';
import './layout.css';
import GeocodeJson from '../Data/Geocodes.json';

//THIS CODE IS TEMPORARY/NECESSARY BECAUSE OF OUR CURRENT AIRTABLE DATA INTEGRITY
let goodObjs = []
AirtableData.records.filter(obj => {
  if (obj.fields.Url !== 'N/A') {
      goodObjs.push(obj)
  }
  return
})

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedGroup: {
        name: '',
        meetup: ''
      }
    };
  }

  _getGroup = (place) => {
    const result = goodObjs.find((record) => record.fields.City === place);
    this.setState({
      selectedGroup: {
        name: result.fields["Group Name"],
        meetup: result.fields["Url"]
      }
    })
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props.place_,
      activeMarker: marker,
      showingInfoWindow: true
    });
    this._getGroup(props.place_.name);
  };

  showDetails = place => {
    console.log(place);
  };

  //   checkInfo = (place) => {
  //     AirtableData.records.forEach((group) => {if(group.fields.City === place.name){
  //         this.setState({groups: [...group.fields["Organizer LinkedIn"]]})
  //         console.log("groups",groups)
  //     }})
  //   };

  containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].fields.City === obj) {
            return true;
        }
    }

    return false;
}


  render() {
    let places = []
    GeocodeJson.locations[0].forEach((loc) => {
      if(this.containsObject(loc.results[0].address_components[0].long_name, goodObjs)){
        places.push({ location: loc.results[0].geometry.location, name: loc.results[0].address_components[0].long_name, placeId: Math.random().toString(36).substr(2, 9) })
      }
      return
    }
    );

    return (
      <div className="map-container">
        <Map
          google={this.props.google}
          className={"map"}
          zoom={2}
          initialCenter={this.props.center}
        >
          {places.map((place, i) => {
            return (
              <Marker
                onClick={this.onMarkerClick}
                key={place.placeId}
                place_={place}
                position={{ lat: place.location.lat, lng: place.location.lng }}
              />
            );
          })}
          <InfoWindowEx
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div onClick={() => window.location.href = this.state.selectedGroup.meetup} className='infoDiv'>
            <small style={{margin: '0 auto'}}>Visit Group:</small>
            <hr style={{ marginTop: '0'}}/>
            <h6 style={{ margin: 0, padding: 0 }}>{this.state.selectedGroup.name}</h6>
            </div>
          </InfoWindowEx>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_MAPS
})(MapContainer);
